exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-author-tsx": () => import("./../../../src/pages/author.tsx" /* webpackChunkName: "component---src-pages-author-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-portfolio-tsx": () => import("./../../../src/pages/portfolio.tsx" /* webpackChunkName: "component---src-pages-portfolio-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-pages-theme-tsx": () => import("./../../../src/pages/theme.tsx" /* webpackChunkName: "component---src-pages-theme-tsx" */),
  "component---src-templates-book-template-tsx": () => import("./../../../src/templates/Book.template.tsx" /* webpackChunkName: "component---src-templates-book-template-tsx" */),
  "component---src-templates-post-template-tsx": () => import("./../../../src/templates/Post.template.tsx" /* webpackChunkName: "component---src-templates-post-template-tsx" */),
  "component---src-templates-project-template-tsx": () => import("./../../../src/templates/Project.template.tsx" /* webpackChunkName: "component---src-templates-project-template-tsx" */),
  "component---src-templates-shortstory-template-tsx": () => import("./../../../src/templates/Shortstory.template.tsx" /* webpackChunkName: "component---src-templates-shortstory-template-tsx" */)
}

